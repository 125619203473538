/**
   ____            ____
   / __ \___ _   __/ __ \____  _____
  / /_/ / _ \ | / / / / / __ \/ ___/
 / _, _/  __/ |/ / /_/ / /_/ (__  )
/_/ |_|\___/|___/\____/ .___/____/
                     /_/
 **/

/* =======================
 * List of classes & ids
 */

.revops-demo,
.revops-demo .h3,
.revops-demo label,
.revops-demo input,
.revops-demo button,
.revops-demo span {
  font-family: Helvetica, Arial, sans-serif;
}
/*
 * -----------------------
 * Links
 * ----------------------- */
a.pay-by-ach-link,
a.pay-by-cc-link {
  float: right;
}
a.pay-by-ach-link:hover,
a.pay-by-cc-link:hover,
.manual-link:hover {
  cursor: pointer;
  text-decoration: underline;
}
.manual-link.single.centered {
  display: block;
  margin: 1rem 0 2rem;
}

/*
 * -----------------------
 * Fields (Card, ACH, Plaid)
 * ----------------------- */

.field-space iframe {
  border: 0 none transparent;
  height: 50px;
  vertical-align: middle;
  width: 100%;
}

.field-space {
  width: 100%;
  display: block;
}

.field-examples {
  width: 500px;
  margin: 0 auto;
  border-radius: 6px;
}

#signup-form .field > label,
#card-form .field > label,
#ach-form .field > label,
#plaid-form .field > label {
  color: #777582;
  font-weight: bold;
  margin-top: 1rem;
}


label.h3 {
  font-size: 1.28571429rem;
  line-height: 1.28571429em;
  margin: calc(2rem - .14285714em) 0 1rem;
  font-weight: 700;
  padding: 0;
}

label.h3:first-child {
  margin-top: 0;
}

.form-container label {
  font-weight: bold;
  display: inline-block;
  line-height: 2rem;
  margin-top: 1rem;
}

/* Container for Buttons */
#button-group {
  height: 7px;
}

/*
 * -----------------------
 * Card Form
 * -----------------------*/
#card-expdate.card-expdate-container,
#card-cvc.card-cvc-container,
#card-postalcode.card-postalcode-container {
  width: 30%;
  display: inline-block;
}
#card-expdate.card-expdate-container,
#card-cvc.card-cvc-container {
  margin-right: 5%
}

/*
 * -----------------------
 * ACH Form
 * -----------------------*/
label.h3 {}
#bank-account-country.bank-account-country-container {
  width: 65%;
  float: right;
}
#bank-postalcode.bank-postalcode-container {
  width: 30%;
}
#bank-postalcode.bank-postalcode-container,
#bank-account-country.bank-account-country-container {
  display: inline-block;
}

/*
 * -----------------------
 * Plaid Form
 * -----------------------*/

/*
 * -----------------------
 * Buttons
 * -----------------------*/

/* Button Styling */
.centered {
  text-align: center;
  display: block;
  margin: 1.7rem auto 0;
}
.centered.single {
  display: block;
  text-align: center;
}

/*
 * -----------------------
 * Terms & Conditions
 * -----------------------*/
#standard-terms {
  max-width: 768px;
  margin: 0 auto;
}
#standard-terms .float-wrapper {
  position: relative;
  width: 100%;
  overflow-y: hidden;
}
#standard-terms .float-above {
  position: fixed;
  z-index: 1;
  bottom: 0;
  background: white;
  padding: 1rem 0;
  border-top: 1px solid #ccc;
  width: 100%;
  max-width: 768px;
}

/*
 * -----------------------
 * Error Messaging
 * -----------------------*/

.validation-error .field-space + span {
  color: red;
  margin-top: 3px;
  display: inline-block;
}
.validation-error .field-space {
  border: 2px solid red;
  border-radius: 4px;
}

/* Error Messaging */
.validation-error .field-space + span {
  color: red;
  margin-top: 3px;
  display: inline-block;
}
.validation-error .field-space {
  border: 2px solid red;
  border-radius: 4px;
}


/* Media Query Reset */
@media only screen and (max-width: 992px) {
  #standard-terms .float-above {
    width: 67vw;
  }
}
@media only screen and (max-width: 767px) {
  .ui.container {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
@media only screen and (max-width: 501px) {
  #standard-terms .float-above {
    width: 84%;
    max-width: 465px;
  }
}
